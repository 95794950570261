const showFrontScroll = () => {
    $('.scroll').show();

    const scroll = $(window).scrollTop();

    if (scroll >= 50) {
        $('.scroll').css({ opacity: 1, bottom: '60px', display: 'block' });
    } else {
        $('.scroll').css({ opacity: 0, bottom: '50px', display: 'none' });
    }
};

const frontScroll = () => {
    $(window).scroll(showFrontScroll);
};

(window as any).goTop = () => {
    $('html, body').animate({ scrollTop: 0 });
};
(window as any).goBottom = () => {
    $('html, body').animate({ scrollTop: document.body.scrollHeight });
};

const initPopup = () => {
    // lightbox
    $('.popup-image').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        mainClass: 'mfp-img-mobile',
        image: {
            verticalFit: true
        }
    });
    
    $('.popup-video').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
    
        fixedContentPos: true,
    });
};

const initFrontPage = () => {
    showFrontScroll();
    frontScroll();
    initPopup();
};

initFrontPage();