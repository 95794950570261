class CommonFuns {
    blockUI(ele: HTMLElement, text: string = '', borderRadius: number = 0, backgroundColor: string = '#fff', svgColor: string = '#000'): void {
        let message = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader spin"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>';

        if (text) {
            message = `
                ${message}
                <br />
                <span style="line-height: 1.8;">${text}</span>
            `;
        }

        $(ele).block({ 
            message,
            timeout: 300000,
            overlayCSS: {
                backgroundColor:    backgroundColor,
                opacity:            0.8,
                cursor:             'default',
            },
            css: {
                border:             0,
                padding:            0,
                color:              svgColor,
                backgroundColor:    'transparent',
                cursor:             'default',
                borderRadius:       borderRadius,
            }
        });
    }

    unblockUI(ele: HTMLElement): void {
        if (ele) {
            $(ele).unblock();
        } else {
            $('*').unblock();
        }
    }

    formValadition(formName: string): boolean {
        const form: any = document.querySelector(`[name=${formName}]`);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

            form.classList.add('was-validated');

            return false;
        }

        return true;
    }

    private toastColor = {
        primary:    '#1b55e2',
        info:       '#2196f3',
        success:    '#8dbf42',
        warning:    '#e2a03f',
        danger:     '#e7515a',
    };
    
    showAlert(text: string, type: ToastType, pos: string = 'top-center') {
        Snackbar.show({
            text:               text,
            actionTextColor:    '#fff',
            backgroundColor:    this.toastColor[type],
            actionText:         '',
            pos,
        });
    }

    async swalFire(title: string, text: string, icon: string = 'warning', confirmButtonText: string = '确认', cancelButtonText: string = '取消') {
        return await Swal.fire({
            title,
            text,
            icon,
            showCancelButton: true,
            confirmButtonColor: '#1cbb8c',
            cancelButtonColor: '#ff3d60',
            confirmButtonText,
            cancelButtonText,
        });
    }

    isEmailValid(email: string) {
        const emailReg  = /^[-_A-Za-z0-9]+@([_A-Za-z0-9]+\.)+[A-Za-z0-9]{2,3}$/;
        const bValid    = emailReg.test(email);
        return bValid;
    }

    // 判断是否是一个有效的手机号码
    isMobileNumber(num: string) {
        const reg       = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
        const bValid    = reg.test(num);
        return bValid;
    }

    parseLocationSearch(url: any) {
        url = url === undefined ? window.location.href : url;
        const search = url.substring(url.lastIndexOf("?") + 1);
        const obj = {};
        const reg = /([^?&=]+)=([^?&=]*)/g;
        search.replace(reg, (rs, $1, $2) => {
            const name  = decodeURIComponent($1);
            const val   = String(decodeURIComponent($2));
            obj[name] = val;
        });

        return obj;
    }
}

const commonFuns           = new CommonFuns();
(window as any).commonFuns = commonFuns;
