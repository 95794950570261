fly.interceptors.request.use((request: any)=>{
    request.timeout = 60000;
    return request;
});

fly.interceptors.response.use(
    (response: any) => {
        if (response.data.status === 'OK') {
            return response.data.result;
        }

        return;
    },
    (error: any) => {
        if (error.response && error.response.data && error.response.data.error) {
            commonFuns.showAlert(error.response.data.error.message, 'danger');

            if (error.response.data.error.message === '登录状态已过期，请重新登录') {
                setTimeout(() => {
                    location.href = '/login';
                }, 2000);
            }
        }
        commonFuns.unblockUI(null);
        return;
    }
);

class HttpFuns {
    async get(url: string, params: any): Promise<any> {
        const result = await this.commonHttpFun('get', url, params);
        return result;
    }

    async post(url: string, params: any): Promise<any> {
        const result = await this.commonHttpFun('post', url, params);
        return result;
    }

    async put(url: string, params: any): Promise<any> {
        const result = await this.commonHttpFun('put', url, params);
        return result;
    }

    async delete(url: string, params: any): Promise<any> {
        const result = await this.commonHttpFun('delete', url, params);
        return result;
    }

    async commonHttpFun(method: string, url: string, params: any): Promise<any> {
        try {
            const result = await fly[method](url, params);
            return result;
        } catch (error) {
            return;
        }
    }
}

const httpFuns              = new HttpFuns();
(window as any).httpFuns    = httpFuns;